import React from 'react'
import { Link } from 'gatsby'

const Navbar = class extends React.Component {
  state = {
    active: false,
    navBarActiveClass: '',
  }

  toggleHamburger = () => {
    const activeState = !this.state.active;

    this.setState({
      active: activeState,
      navBarActiveClass: activeState ? 'is-active' : '',
    });
  }

  render() {
    return (
      <nav
        className="navbar"
        role="navigation"
        aria-label="main-navigation"
      >
        <h1><Link to="/">travel with a cup of chai</Link></h1>
        <button
          className={`navbar-burger burger ${this.state.navBarActiveClass}`}
          onClick={this.toggleHamburger}
          onKeyDown={this.toggleHamburger}
          data-target="navMenu"
        >
          <span />
          <span />
          <span />
        </button>

        <ul id="navMenu" className={`navbar-menu ${this.state.navBarActiveClass}`}>
          <li><Link to="/about">ABOUT ME</Link></li>
          <li><Link to="/my-footsteps">MY FOOTSTEPS</Link></li>
          <li><Link to="/blog">BLOG POSTS</Link></li>
          {/* <li><Link to="/itineraries">TRAVEL ITINERARIES</Link></li> */}
        </ul>
      </nav>
    )
  }
}

export default Navbar
